// The Vue build version to load with the `import` command (runtime-only or
// standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui';
import "babel-polyfill"
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import axios from 'axios';
import { VueJsonp } from 'vue-jsonp' 

Vue.prototype.axios = axios;
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueJsonp)
// 使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  const ignoreToM = to.meta.ignoreToM // 是否不检验移动端
  document.title = to.meta.title || '河小象-专注硬笔书法教学-学练评测闭环练字在家就能学'
  let path = to.path;
  let key = localStorage.getItem("key") || ""; //有key就等于登录了
  let toMobile = !ignoreToM && path.indexOf('/m/') != -1
    ? true
    : false; // 要跳转到移动端页面
  let toPath = toMobile == true
    ? path.replace('/m/', '/')
    : path; // 要跳转的路径
    if(to.query.source){
      localStorage.setItem("source",to.query.source)
    }
    // 判断路由是否携带source
  if (IsPC()) {
    if (toMobile) {
      next(toPath);
    } else {
      let version = IEVersion();
      console.log('IE', version)
      if (version === 6 || version === 7 || version === 8 || version === 9 || version === 10) {
        if (toPath.indexOf('uplodeIE') != -1) {
          next();
        } else {
          next('/uplodeIE');
        }
      } else {
        if (key == "" && toPath !== '/login' && toPath === '/classroom') {
          next('/login');
        } else {
          next();
        }
      }

    }
  } else {
    let newPath = '/m' + toPath;
    if (toMobile) {
      // if (path.indexOf('video') != -1 || path.indexOf('login') != -1) {
      // next('/m/'); } else {     next(); }
      next();
    } else {
      if (ignoreToM) {
        next()
      } else {
        if (path.indexOf('login') != -1 || path.indexOf('classroom') != -1) {
          next('/m/');
        } else if (path.indexOf('explain') != -1) {
          next();
        } else {
          next(newPath);
        }
      }
    }
  }

})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

function IEVersion() {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
  var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
  var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  if (isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    var fIEVersion = parseFloat(RegExp["$1"]);
    if (fIEVersion == 7) {
      return 7;
    } else if (fIEVersion == 8) {
      return 8;
    } else if (fIEVersion == 9) {
      return 9;
      // } else if(fIEVersion == 10) {     return 10; } else {
      return 6; //IE版本<=7
    }
  } else if (isEdge) {
    return 'edge'; //edge
  } else if (isIE11) {
    return 11; //IE11
  } else {
    return -1; //不是ie浏览器
  }
}

function IsPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone");
  var flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

/* eslint-disable no-new */
new Vue({el: '#app', router, components: {
    App
  }, template: '<App/>'})
