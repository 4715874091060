<template>
  <div id="app" :class="isPC == true ? 'minWidth' : ''">
    <router-view class="conatiner"></router-view>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
export default {
  name: "App",
  data() {
    return {
      isPC: false,
      requestURL: 'https://yw.hexiaoxiang.com',
    }
  },
  created() {
    this.isPC = this.IsPC();
    this.wxShare()
  },
  methods: {
    wxShare(){
      console.log(location.href.split('#')[0])
      //获取并编码#前的当前页面地址
      let shareUrl = encodeURIComponent(location.href.split('#')[0]);
      let url = this.requestURL + '/zh/api/xcx_sdk_signature?url=' + shareUrl;
      //获取签名等设置微信分享所需参数
      this.axios.get(url, { headers: { 'Content-Type': 'application/json;charset=utf-8' } })
      .then(res => {
        if (res.data.code == 20000) {
          this.config = res.data;
          this.setConfig();
        }
      }).catch(function(error) {
        console.log(error);
      });

    },
    getShareConfig() {
      let result = {
        title: '河小象', // 分享标题
        desc: '好字源自好方法，写字就上河小象',
        imgUrl: 'https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/share-logo.png',
      }

      if (this.$route.name === 'xiaoshutong') {
        result = {
          title: document.title, // 分享标题
          desc: '课内课外全面辅导，轻松提高学习成绩！',
          imgUrl: 'https://xcx.alicdn2.hexiaoxiang.com/h5/page_config/imgs/shu-tong-logo.png',
        }
      }

      return result
    },
    setConfig(){
      let link = window.location.href;
      let shareInfo = {
        ...this.getShareConfig(),
        link,
        success: () => {
          this.isShareTip = false;
          console.log('分享设置成功');
        },
        fail: function() {
          console.log('分享设置失败');
        },
      };
      wx.config({
        debug: false,
        ...this.config.data,
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
      });
      wx.ready(function() {
        //分享给朋友
        wx.updateAppMessageShareData(shareInfo);
        wx.updateTimelineShareData(shareInfo);
      });
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone");
      var flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (flag && window.location.pathname.indexOf('classroom') !== -1) {
        flag = false;
      }
      return flag;
    }
  },
  watch: {
    $route(to) {
      if (this.isPC && to.path.indexOf('classroom') != -1) {
        this.isPC = false;
      }
    }
  }
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}
ul, li{
  padding: 0;
  margin: 0;
  list-style: none;
}
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.conatiner{
  width:100%;
}
.minWidth {
  min-width: 1120px;
}
p {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6{
  margin: 0;
}
input{
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
}
button{
  border: none;
  cursor: pointer;
  background: transparent;
  outline: none;
}
img{
  border: none;
  vertical-align: middle;
}
</style>
