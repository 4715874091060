import Vue from 'vue'
import Router from 'vue-router'

const root = () => import ('@/pages/root')
const m = () => import ('@/pages/mLayout') // 改版
const login = () => import ('@/pages/login')
const videoHome = () => import ('@/pages/videoHome')
const appdownload = () => import ('@/components/appdownload')
const contact = () => import ('@/components/contact')
const mHome = () => import ('@/pages/mHome') // 改版
const mPoetry =() => import ('@/pages/mPoetry') //移动端古诗词
const mRead=() => import('@/pages/mRead')
const mWrite = () => import('@/pages/mWrite')
const mComposition = () => import('@/pages/mComposition')
const mAbout = () => import('@/pages/mAbout')
const mAboutHxx = () => import('@/pages/mAboutHxx')
const mContact =() => import('@/pages/mContactUs')
const mAggreement =() => import('@/pages/mAggreement')
const mJoin =()=> import('@/pages/mJoin')
const mPartner = () => import ('@/components/mPartner')
const mTeacher = () => import ('@/components/mTeacher')
const join = () => import ('@/components/join')
const home = () => import ('@/components/home')
const mDownload = () => import ('@/components/mDownload')
const foot = () => import ('@/components/foot')
const uplodeIE = () => import ('@/components/uplodeIE')
const mAgreement = () => import ('@/components/mAgreement')
const mExplain = () => import ('@/components/mExplain')
const readHome =() => import ('@/pages/readHome')
const music = () => import ('@/pages/music')
const dailyPoetry = () => import ('@/pages/dailyPoetry')
const homePage=()=> import ('@/pages/homePage')
const artDownload = () => import ('@/pages/artDownload')
const writeClass = () => import ('@/pages/writeClass')
const dailyComposition=()=> import ('@/pages/dailyComposition')
const we = () => import('@/pages/we')
const aboutUs = () => import('@/pages/aboutUs')
const aboutHxx = () => import('@/pages/aboutHxx')
const contactUs =() => import('@/pages/contactUs')
const partner =() => import('@/pages/partner')
const aggreement =() => import('@/pages/aggreement')
const JoinUs =()=> import('@/pages/joinUs')
const brand = ()=> import('@/pages/brand')
const mBrand =() => import('@/pages/mBrand')
const news=()=> import('@/pages/news')
const mNews =()=> import('@/pages/mNews')
const modelEssay = ()=> import('@/pages/modelEssay')
const essayDetail = () => import('@/pages/essayDetail')
const DaYuWen = () => import('@/pages/daYuWen')
const ShuTong = () => import('@/pages/shuTong')
const IOSYW = () => import('@/pages/ios/yuwen')
const UnityApp = () => import('@/pages/unityApp')
const spDowload = () => import('@/pages/app/smartpen/download')

Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [
    {
      path: '/m/',
      name: 'm',
      component: m,
      redirect:{name:'mHome'},
      children: [
        {
          path: 'mHome',
          name: 'mHome',
          component: mHome
        },
        {
          path: 'poetry',
          name: 'mPoetry',
          component: mPoetry
        },
        {
          path:'composition',
          name:'mComposition',
          component:mComposition
        },
        {
          path:'write',
          name:'mWrite',
          component:mWrite
        },
        {
          path: 'mRead',
          name: 'mRead',
          component: mRead
        },
        {
          path:'about',
          name:'mAbout',
          component:mAbout
        },
        {
          path:'aboutHxx',
          name:'mAboutHxx',
          component:mAboutHxx
        },
        {
          path:'mNews',
          name:'mNews',
          component:mNews
        },
        // {
        //   path: 'teacher',
        //   name: 'mTeacher',
        //   component: mTeacher
        // },
        {
          path:'mBrand',
          name:'mBrand',
          component:mBrand
        },
        {
          path: 'contact',
          name: 'mContact',
          component: mContact
        },
        {
          path: 'download',
          name: 'mDownload',
          component: mDownload
        },
        {
          path: 'aggreement',
          name: 'mAggreement',
          component: mAggreement
        },
        {
          path:'join',
          name:'mJoin',
          component:mJoin
        }
      ],
      redirect: '/m/mHome',
    },
    {
      path: '/explain',
      name: 'mExplain',
      component: mExplain
    },
    {
      path: '/classroom',
      name: 'videoHome',
      component: videoHome
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/uplodeIE',
      name: 'uplodeIE',
      component: uplodeIE
    },
    {
      path: '/',
      name: 'root',
      component: root,
      children: [
        {
          path: 'teacher',
          component: join
        },
        {
          path: 'artDownload',
          component: artDownload
        },
        {
          path: 'partner',
          component: partner
        },
        {
          path: 'download',
          component: appdownload
        },        
        // {
        //   path: '',
        //   component: home
        // },
        // {
        //   path: 'contact',
        //   component: contact
        // },
        {
          path: 'foot',
          component: foot
        },
        // {
        //   path: '/agreement',
        //   name: 'agreement',
        //   component: agreement
        // },
        {
          path:'/read',
          name:'read',
          component: readHome
        },
        {
          path:'/news',
          name:'news',
          component:news
        },
        {
          path:'/',
          name:'homePage',
          component:homePage
        },
        {
          path:'/aboutHxx',
          name:'aboutHxx',
          component:aboutHxx
        },
        // {
        //   path: '/dailyPoetry',
        //   name: 'dailyPoetry',
        //   component: dailyPoetry
        // },
        {
          path: '/writeClass',
          name: 'writeClass',
          component: writeClass
        },
        // {
        //   path:'/dailyComposition',
        //   name:'dailyComposition',
        //   component:dailyComposition
        // },
        {
          path:'/music',
          name:'music',
          component:music
        },
        {
          path:'/modelEssay',
          name:'modelEssay',
          component:modelEssay
        },
        {
          path:'/essayDetail',
          name:'essayDetail',
          component:essayDetail
        },
        {
          path:'/we',
          name:'we',
          component:we,
          children:[
            {
              path: '/about',
              name: 'about',
              component: aboutUs
            },
            {
              path:'/aboutUs',
              name:'aboutUs',
              component:aboutUs
            },            
            {
              path:'/contactUs',
              name:'contactUs',
              component:contactUs
            },{
              path:'/partner',
              name:'partner',
              component:partner
            },
            {
              path:'/aggreement',
              name:'aggreement',
              component:aggreement
            },
            {
              path:'/JoinUs',
              name:'joinUs',
              component:JoinUs
            },{
              path:'/brand',
              name:'brand',
              component:brand
            }
          ]
        }
      ]
    },
    {
      path: '/download/xiaoshutong',
      name: 'xiaoshutong',
      meta: {
        ignoreToM: true, // 不经过m的重定向
        title: '小书童App - 小学语文同步学习神器'
      },
      component: ShuTong
    },
    {
      path: '/app/smartpen/download',
      name: 'download',
      meta: {
        ignoreToM: true, // 不经过m的重定向
        title: '智能陪练笔APP下载'
      },
      component: spDowload
    },
    {
      path: '/download/dayuwen',
      name: 'dayuwen',
      meta: {
        ignoreToM: true, // 不经过m的重定向
        title: '河小象大语文APP'
      },
      component: DaYuWen
    },
    {
      path: '/download/hexiaoxiangyw',
      name: 'ywApp',
      meta: {
        ignoreToM: true, // 不经过m的重定向
        title: '河小象少儿全科素质课堂'
      },
      component: UnityApp
    },
    {
      path: '/app/lexue',
      name: 'appLexue',
      meta: {
        ignoreToM: true, // 不经过m的重定向
        title: '河小象少儿全科素质课堂'
      },
      component: UnityApp
    },
    {
      path: '/ios/dayuwen',
      name: 'iOSYuWen',
      meta: {
        ignoreToM: true,
        title: '河小象少儿全科素质课堂'
      },
      component: UnityApp
    },
    {
      path: '*',
      redirect: '/'
    },
  ]
})
